import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import {
  title_MISS,
  title_MR,
  title_MRS,
  title_MS,
} from 'helpers/referenceDataConstants';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type TitlesReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type TitlesReferenceData = {
  titles: ReferenceDataOption[];
};

// order defined as per EP-1235
export const titlesInOrder = [title_MR, title_MRS, title_MISS, title_MS];

export const mapTitlesRefData = (
  data: TitlesReferenceDataResponse
): TitlesReferenceData => ({
  titles: data.items
    .map(mapApiOption)
    .sort((a, b) => titlesInOrder.indexOf(a.value) - titlesInOrder.indexOf(b.value)),
});
