import { NonTextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import {
  Grid,
  GridItem,
  GridItemProps,
} from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackIconButtonClick } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';
import {
  BackgroundContainer,
  Hero,
  ImageWrapper,
  SizedImage,
  Subheading,
} from './styles';

type HeroBannerProps = {
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
  subheadingGridItemProps?: GridItemProps;
  pageTitle: PageTitle;
} & ComponentProps;

type CsHeroBanner = {
  csHeroHeader: {
    john_lewis_logo: CsAsset | null;
    logo_link_url: string;
    link_screen_reader_text: string;
  };
};

const query = graphql`
  query {
    csHeroHeader {
      john_lewis_logo {
        ...CsAsset
      }
      logo_link_url
      link_screen_reader_text
    }
  }
`;

const initialSubheadingGridItemProps: GridItemProps = {
  desktop: 8,
  tabletLandscape: 8,
  tabletPortrait: 6,
};

const HeroBanner: React.FC<HeroBannerProps> = ({
  heading,
  subheading,
  subheadingIsPii,
  subheadingGridItemProps,
  pageTitle,
  ...props
}) => {
  const { john_lewis_logo, logo_link_url, link_screen_reader_text } = useStaticQuery<
    CsHeroBanner
  >(query).csHeroHeader;
  const logo = processImageAsset(john_lewis_logo);

  const logoLink: NonTextLink = {
    url: logo_link_url,
    screenReaderText: link_screen_reader_text,
    onClick: () => trackIconButtonClick(pageTitle, 'John Lewis Logo'),
  };
  const logoLinkSelectPagesOnly =
    pageTitle === PageTitle.AboutYourPet || pageTitle === PageTitle.Confirmation
      ? logoLink
      : undefined;

  return (
    <BackgroundContainer {...componentProps(props)}>
      {logo && (
        <ImageWrapper>
          <SizedImage link={logoLinkSelectPagesOnly} image={logo} />
        </ImageWrapper>
      )}
      {heading && (
        <Grid>
          <GridItem desktop={6} tabletLandscape={6}>
            <Hero>{heading}</Hero>
          </GridItem>
        </Grid>
      )}
      {subheading && (
        <Grid>
          <GridItem {...initialSubheadingGridItemProps} {...subheadingGridItemProps}>
            {subheadingIsPii ? (
              <Subheading data-cs-mask={subheadingIsPii}>{subheading}</Subheading>
            ) : (
              <Subheading>{subheading}</Subheading>
            )}
          </GridItem>
        </Grid>
      )}
    </BackgroundContainer>
  );
};

export default HeroBanner;
