import { Assumptions, getAssumptionsForQuote } from 'businessLogic/aggregatorAssumptions';
import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';

type CsAggregatorAssumptions = {
  csPetAggregatorsV2: {
    assumptions: {
      good_health: {
        assumption_single_pet: string;
        assumption_multiple_pets: string;
      };
      no_complaints_about_behaviour: {
        assumption: string;
      };
      not_involved_in_legal_action: {
        assumption: string;
      };
      one_pet_in_household: {
        assumption: string;
      };
      two_pets_in_household: {
        assumption: string;
      };
      three_pets_in_household: {
        assumption: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAggregatorsV2 {
      assumptions {
        good_health {
          assumption_single_pet
          assumption_multiple_pets
        }
        no_complaints_about_behaviour {
          assumption
        }
        not_involved_in_legal_action {
          assumption
        }
        one_pet_in_household {
          assumption
        }
        two_pets_in_household {
          assumption
        }
        three_pets_in_household {
          assumption
        }
      }
    }
  }
`;

const useAssumptions = (): Assumptions | undefined => {
  const {
    csPetAggregatorsV2: { assumptions: assumptionsContent },
  } = useStaticQuery<CsAggregatorAssumptions>(query);
  const currentQuote = useCurrentQuote();

  const [assumptionsAgreement] = useAssumptionsAgreement();

  if (
    assumptionsAgreement.assumptionsInvalidDueToRequote &&
    assumptionsAgreement.assumptionsInvalidDueToNavigatingToAboutYourPet
  ) {
    return undefined;
  }

  return getAssumptionsForQuote(
    currentQuote,
    {
      goodHealthSinglePet: assumptionsContent.good_health.assumption_single_pet,
      goodHealthMultiplePets: assumptionsContent.good_health.assumption_multiple_pets,
      noComplaintsAboutBehaviour:
        assumptionsContent.no_complaints_about_behaviour.assumption,
      notInvolvedInLegalAction:
        assumptionsContent.not_involved_in_legal_action.assumption,
      onePetInHousehold: assumptionsContent.one_pet_in_household.assumption,
      twoPetsInHousehold: assumptionsContent.two_pets_in_household.assumption,
      threePetsInHousehold: assumptionsContent.three_pets_in_household.assumption,
    },
    assumptionsAgreement.touchedAssumptions
  );
};

export default useAssumptions;
