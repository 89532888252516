import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import React, { useEffect } from 'react';
import Analytics from 'components/Analytics';
import ComplianceFooter from 'components/Footer/ComplianceFooter';
import HeroBanner from 'components/Header/HeroBanner';
import Meta from 'components/Meta';
import ProgressBar, { QuoteAndBuyStep } from 'components/ProgressBar';
import ReCaptcha from 'components/ReCaptcha';
import SessionExpiryWrapper, {
  SessionExpiryOption,
} from 'components/SessionExpiry/SessionExpiryWrapper';
import { activeEnv } from 'helpers/activeEnvironment';
import { PageTitle } from 'helpers/eventTracking';
import { reloadOneTrust } from 'helpers/oneTrustHelper';
import RefreshButton from './refresh';
import { FooterGridContainer, StyledHeroBanner } from './styles';

const Layout: React.FC<{
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
  currentStep?: QuoteAndBuyStep;
  metaTitle: string;
  pageTitle: PageTitle;
  sessionExpiryOption?: SessionExpiryOption;
  subheadingGridItemProps?: GridItemProps;
  isErrorPage?: boolean;
}> = ({
  children,
  currentStep,
  heading,
  subheading,
  subheadingIsPii,
  metaTitle,
  pageTitle,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
  subheadingGridItemProps,
  isErrorPage,
}) => {
  useEffect(() => {
    reloadOneTrust();
  }, []);
  return (
    <SessionExpiryWrapper pageTitle={pageTitle} sessionExpiryOption={sessionExpiryOption}>
      <ReCaptcha />
      {activeEnv !== 'test' && <Analytics />}
      {activeEnv === 'preview' && /* istanbul ignore next */ <RefreshButton />}
      <Meta metaTitle={metaTitle} />
      <GridContainer as="header">
        {isErrorPage ? (
          <StyledHeroBanner
            heading={heading}
            subheading={subheading}
            subheadingIsPii={subheadingIsPii}
            subheadingGridItemProps={subheadingGridItemProps}
            pageTitle={pageTitle}
          />
        ) : (
          <HeroBanner
            heading={heading}
            subheading={subheading}
            subheadingIsPii={subheadingIsPii}
            subheadingGridItemProps={subheadingGridItemProps}
            pageTitle={pageTitle}
          />
        )}
        {currentStep && <ProgressBar currentStep={currentStep} pageTitle={pageTitle} />}
      </GridContainer>
      <GridContainer as="main">
        {children}
        <FooterGridContainer as="footer">
          <ComplianceFooter pageTitle={pageTitle} />
        </FooterGridContainer>
      </GridContainer>
    </SessionExpiryWrapper>
  );
};

export default Layout;
