import Spinner from '@rsa-digital/evo-shared-components/components/Spinner';
import mapPetsDetails from 'apiHelpers/quote/quoteToFormMappings/mapPetsDetails';
import useGenerateQuote from 'apiHelpers/quote/useGenerateQuote';
import quoteClient, { DeeplinkRequest } from 'apiHelpers/quoteClient';
import { PetCoverLevelReferenceData } from 'apiHelpers/referenceData/petCoverLevel';
import {
  shouldAggsQuoteShowAdditionalQuestions,
  shouldAggsQuoteShowAdditionalQuestionsNonCTM,
} from 'businessLogic/aggregators';
import { graphql, navigate } from 'gatsby';
import React, { Dispatch, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import WhyChooseUsSection from 'components/WhyChooseUsSection';
import { CoverLevel, ProductId } from 'helpers/businessConstants';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackDeeplinkReferral } from 'helpers/eventTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import { usePageTracking } from 'helpers/pageTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { dogBreedType_PEDIGREE } from 'helpers/referenceDataConstants';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import {
  CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import { UPDATE_PETS_DETAILS, UpdatePetsDetailsAction } from 'state/formData/petsDetails';
import { useInitialiseQuoteWithCoverLevel } from 'state/quote/loadQuoteHelper';
import useReferenceData from 'state/referenceData/useReferenceData';
import {
  BottomDivider,
  GridWithMargin,
  Heading,
  HeadingGridItem,
  HeadingWrapper,
  TopDivider,
  WhyChooseUsGridItem,
} from './styles';

const PAGE_LANDING_STEP = 5;

type LoadingQuoteLocation = Location & {
  state?: {
    shouldNotInvalidateAssumptions?: boolean;
  };
};

type LoadingQuoteProps = {
  data: {
    csPetLoadingPage: {
      meta_title: string;
      heading: string;
    };
  };
  location: LoadingQuoteLocation;
};

export const query = graphql`
  query {
    csPetLoadingPage {
      meta_title
      heading
    }
  }
`;

export const getDeepLinkParams = (location: Location): DeeplinkRequest | null => {
  const quoteNumber = getQueryParam(location, 'qn');
  const postcode = getQueryParam(location, 'pc');
  const checksum = getQueryParam(location, 'checksum');
  const msmUrn = getQueryParam(location, 'URN');
  const msmSsid = getQueryParam(location, 'ssid');
  const confusedRef = getQueryParam(location, 'ref');
  const gocoUuid = getQueryParam(location, 'convert_uuid');

  if (!quoteNumber || !postcode || !checksum) {
    return null;
  }

  if (confusedRef) {
    storeData(CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY, confusedRef);
  }

  return {
    qn: quoteNumber,
    pc: postcode,
    checksum,
    msmUrn: msmUrn ?? undefined,
    msmSsid: msmSsid ?? undefined,
    confusedRef: confusedRef ?? undefined,
    gocoUuid: gocoUuid ?? undefined,
  };
};

const getCoverLevelFromQueryParams = (
  location: Location,
  coverLevels: PetCoverLevelReferenceData
): CoverLevel | undefined => {
  // The tier gotten from the aggregator doesn't always match exactly with the reference data, but they only differ
  // by non alphanumeric characters, so we remove any non alphanumeric characters before comparing.
  const invalidRegex = new RegExp('[^A-Za-z0-9]', 'g');
  const aggregatorCoverLevel = getQueryParam(location, 'tier')?.replace(invalidRegex, '');

  if (!aggregatorCoverLevel) {
    return undefined;
  }

  return (coverLevels.petCoverLevel.find(
    (level) => level.name.replace(invalidRegex, '') === aggregatorCoverLevel
  )?.value || undefined) as CoverLevel | undefined;
};

const LoadingQuote: React.FC<LoadingQuoteProps> = ({
  data: {
    csPetLoadingPage: { heading, meta_title },
  },
  location,
}) => {
  const initialiseQuoteWithCoverLevel = useInitialiseQuoteWithCoverLevel();
  const defaultErrorHandling = useDefaultErrorHandling();
  const [requestStarted, setRequestStarted] = useReducer(() => true, false);

  const { createQuote } = useGenerateQuote(
    location.state?.shouldNotInvalidateAssumptions
  );

  const disableDateChecks = useDisableDateChecks();

  const petCoverLevels = useReferenceData('petCoverLevel');
  const dispatchPetsDetails = useDispatch<Dispatch<UpdatePetsDetailsAction>>();
  const fetchQuoteAndMoveNext = useCallback(async (): Promise<void> => {
    try {
      const deepLinkParams = getDeepLinkParams(location);
      if (deepLinkParams) {
        if (!petCoverLevels) {
          return;
        }
        setRequestStarted();
        const quote = await quoteClient.deeplink({
          ...deepLinkParams,
          disableDateChecks,
        });
        await initialiseQuoteWithCoverLevel(
          quote,
          getCoverLevelFromQueryParams(location, petCoverLevels)
        );
        if (shouldAggsQuoteShowAdditionalQuestions(quote)) {
          if (shouldAggsQuoteShowAdditionalQuestionsNonCTM(quote)) {
            dispatchPetsDetails({
              type: UPDATE_PETS_DETAILS,
              update: mapPetsDetails(quote.petInfos).map((pet) => ({
                ...pet,
                dogBreedType:
                  pet.dogBreedType === dogBreedType_PEDIGREE &&
                  quote.productId !== ProductId.CONFUSED
                    ? dogBreedType_PEDIGREE
                    : '',
                mongrelSize: '',
              })),
            });
          }
          navigate(quoteAndBuyRoutes.additionalQuestions, { replace: true });
        } else {
          navigate(quoteAndBuyRoutes.quoteSummary, { replace: true });
        }
      } else {
        setRequestStarted();
        await createQuote();
        navigate(quoteAndBuyRoutes.quoteSummary, { replace: true });
      }
    } catch (e) {
      defaultErrorHandling(e);
    }
  }, [
    createQuote,
    defaultErrorHandling,
    disableDateChecks,
    dispatchPetsDetails,
    initialiseQuoteWithCoverLevel,
    location,
    petCoverLevels,
  ]);

  useEffect(() => {
    if (getDeepLinkParams(location)) {
      trackDeeplinkReferral(document.referrer);
    }
  }, [location]);

  useEffect(() => {
    if (!requestStarted) {
      fetchQuoteAndMoveNext();
    }
  }, [fetchQuoteAndMoveNext, requestStarted]);

  const quote = useCurrentQuote();
  usePageTracking(meta_title);
  useCheckoutTracking(PAGE_LANDING_STEP, quote, true);

  useEffect(() => {
    const unloadCallback = (event: {
      preventDefault: () => void;
      returnValue: string;
    }): string => {
      event.preventDefault();
      const ev = event;
      ev.returnValue =
        'Refreshing the page may cause you to lose your data. Are you sure you want to refresh?';
      return event.returnValue;
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const replaceQuotePlaceholders = replacePlaceholdersPlainText(
    quotePlaceholders,
    quote,
    true
  );

  return (
    <Layout pageTitle={PageTitle.QuoteGenerating} metaTitle={meta_title}>
      <GridWithMargin>
        <HeadingGridItem desktop={6} tabletLandscape={6} tabletPortrait={6} aria-hidden>
          <HeadingWrapper>
            <Spinner aria-label="Loading page animation" />
            <Heading>{replaceQuotePlaceholders(heading)}</Heading>
          </HeadingWrapper>
        </HeadingGridItem>
        <WhyChooseUsGridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
          <TopDivider />
          <WhyChooseUsSection pageTitle={PageTitle.QuoteGenerating} />
          <BottomDivider />
        </WhyChooseUsGridItem>
      </GridWithMargin>
    </Layout>
  );
};

export default LoadingQuote;
