import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle, trackLinkClick } from 'helpers/eventTracking';
import { CsProgressBar } from 'types/contentStack';
import { StyledBackground, StyledProgressBar } from './styles';

type ProgressBarProps = {
  currentStep: QuoteAndBuyStep;
  pageTitle: PageTitle;
} & ComponentProps;

type ProgressBarCsProps = {
  csPetProgressBarV2: CsProgressBar;
};

export const query = graphql`
  query {
    csPetProgressBarV2 {
      about_your_pet_label
      about_you_label
      about_your_policy_label
      quote_summary_label
      check_details_label
      payment_label
      show_progress_text
      hide_progress_text
    }
  }
`;

export enum QuoteAndBuyStep {
  AboutYourPet = 1,
  AboutYou = 2,
  AboutYourPolicy = 3,
  QuoteSummary = 4,
  CheckDetails = 5,
  Payment = 6,
}

const ProgressBarComponent: React.FC<ProgressBarProps> = ({
  currentStep,
  pageTitle,
  ...props
}) => {
  const {
    csPetProgressBarV2: {
      show_progress_text,
      hide_progress_text,
      about_your_pet_label,
      about_you_label,
      about_your_policy_label,
      quote_summary_label,
      check_details_label,
      payment_label,
    },
  } = useStaticQuery<ProgressBarCsProps>(query);

  const steps = [
    about_your_pet_label,
    about_you_label,
    about_your_policy_label,
    quote_summary_label,
    check_details_label,
    payment_label,
  ];

  return (
    <>
      <StyledBackground />
      <StyledProgressBar
        {...componentProps(props)}
        id="progressBar"
        currentStep={currentStep}
        totalSteps={steps.length}
        stepName={steps}
        desktopWidthInPercentage={75}
        tabletWidthInPercentage={90}
        showProgressText={show_progress_text}
        hideProgressText={hide_progress_text}
        onToggleProgress={(progressShown) =>
          trackLinkClick(pageTitle, progressShown ? 'Hide progress' : 'Show progress')
        }
      />
    </>
  );
};

export default ProgressBarComponent;
