import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { CustomerDetails } from 'state/formData/customerDetails';
import mapAddress from './mapAddress';
import { QuoteCustomerInfo, QuotePolicyInfo } from '../quoteRequest';

const mapCustomerDetails = (
  customerInfo: QuoteCustomerInfo,
  policyInfo: QuotePolicyInfo
): CustomerDetails => {
  return {
    customerFirstName: customerInfo.firstName,
    customerLastName: customerInfo.lastName,
    customerTelephone: customerInfo.contactPhoneNumber,
    customerEmail: customerInfo.email,
    customerDob: dateToDateValue(new Date(customerInfo.dob)),
    customerTitle: customerInfo.title,
    customerGender: customerInfo.gender,
    numberOfPetsInHousehold: policyInfo.numberPetInHousehold,
    ...mapAddress(customerInfo.address),
  };
};

export default mapCustomerDetails;
