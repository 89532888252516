import WhyChooseUs from '@rsa-digital/evo-shared-components/components/WhyChooseUs';
import { ReasonWrapper } from '@rsa-digital/evo-shared-components/components/WhyChooseUs/styles';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledWhyChooseUs = styled(WhyChooseUs)`
  ${ReasonWrapper} {
    max-width: ${spacing(34)};
    margin-left: auto;
    margin-right: auto;
    ${mediaQuery.tabletPortrait`
        max-width: none;
        
    `}
  }
`;
