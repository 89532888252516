export const CORE_COVER = 'NWA_PET_9382';

export const OVER_9_EXCESS_PERCENTAGE = 20;

export enum CoverLevel {
  VET_FEE_LIMIT_2000 = 'NWA_PET_e',
  VET_FEE_LIMIT_3000 = 'NWA_PET_f',
  VET_FEE_LIMIT_5000 = 'NWA_PET_g',
  VET_FEE_LIMIT_7500 = 'NWA_PET_h',
  VET_FEE_LIMIT_9000 = 'NWA_PET_i',
  VET_FEE_LIMIT_12000 = 'NWA_PET_j',
}

export enum VoluntaryExcessAmount {
  Excess_60 = '0060',
  Excess_80 = '0080',
  Excess_100 = '0100',
  Excess_140 = '0140',
  Excess_250 = '0250',
  Excess_500 = '0500',
}

export enum VoluntaryExcessPercentage {
  EXCESS_0_PERCENT = '0000',
  EXCESS_10_PERCENT = '010P',
  EXCESS_20_PERCENT = '020P',
}

export enum CoverLimit {
  VET_FEE_LIMIT_2000 = 2000,
  VET_FEE_LIMIT_3000 = 3000,
  VET_FEE_LIMIT_5000 = 5000,
  VET_FEE_LIMIT_7500 = 7500,
  VET_FEE_LIMIT_9000 = 9000,
  VET_FEE_LIMIT_12000 = 12000,
}

export enum ProductId {
  DIRECT = '0000000062',
  GO_COMPARE = '0000000065',
  COMPARE_THE_MARKET = '0000000069',
  MONEY_SUPERMARKET = '0000000092',
  CONFUSED = '0000000068',
}

export enum CoverType {
  ACCIDENT_AND_ILLNESS = 'Accidents_And_Illness',
}

export enum VetBillsAccidentsAndIllness {
  ONGOING = 'Ongoing_Treatments',
}

export enum MarketingProduct {
  JOHN_LEWIS = 'NWA_PET_MKTJL',
  WAITROSE = 'NWA_PET_MKTWT',
  JOHN_LEWIS_FINANCIAL_SERVICES = 'NWA_PET_MKTGB',
}

export enum DesignConstancts {
  PET_BREED_DROPDOWN_MENU_MAX_HEIGHT = '412',
}
