import {
  getBundleFromQuote,
  getSelectedBundleCovers,
} from 'apiHelpers/quote/bundleCoverMapping';
import { QuoteCustomerInfo, QuotePolicyInfo } from 'apiHelpers/quote/quoteRequest';
import { Bundle, Quote, QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import {
  ProductType,
  QuoteOptions,
  UPDATE_QUOTE_OPTIONS,
} from 'state/formData/quoteOptions';
import { Prices, PriceSummary } from 'state/quote/types';
import { CoverLevel, ProductId } from './businessConstants';

export type CurrentQuote = {
  petInfos: QuoteResponsePetInfo[] | null;
  customerInfo: QuoteCustomerInfo | null;
  policyInfo: QuotePolicyInfo | null;
  quoteOptions: QuoteOptions;
  price: Prices | null;
  productId: ProductId;
  selectedBundleCovers: string[];
  confusedDeeplinkRefValue: string | undefined;
};

const parsePricePerPet = (
  pricePerPet: number[]
): { total: number; perPet: number[] } => ({
  total: parseFloat(pricePerPet.reduce((total, price) => total + price).toFixed(2)),
  perPet: pricePerPet.map((price) => parseFloat(price.toFixed(2))),
});

export const getPricesFromBundle = (bundle: Bundle): PriceSummary => {
  const {
    annualPrice: { petPrices: annualPricePerPet },
    installments,
  } = bundle;
  const monthlyPricePerPet = installments[0].petPrices;

  return {
    annualPrice: parsePricePerPet(annualPricePerPet),
    monthlyPrice: parsePricePerPet(monthlyPricePerPet),
  };
};

const getPricesFromQuote = (
  quote: Quote,
  coverLevel: CoverLevel
): PriceSummary | null => {
  const bundle = getBundleFromQuote(coverLevel, quote);
  return bundle ? getPricesFromBundle(bundle) : null;
};

const getAllPricesFromQuote = (quote: Quote): Prices => ({
  vetFeeLimit2000: getPricesFromQuote(quote, CoverLevel.VET_FEE_LIMIT_2000) || null,
  vetFeeLimit3000: getPricesFromQuote(quote, CoverLevel.VET_FEE_LIMIT_3000) || null,
  vetFeeLimit5000: getPricesFromQuote(quote, CoverLevel.VET_FEE_LIMIT_5000) || null,
  vetFeeLimit7500: getPricesFromQuote(quote, CoverLevel.VET_FEE_LIMIT_7500) || null,
  vetFeeLimit9000: getPricesFromQuote(quote, CoverLevel.VET_FEE_LIMIT_9000) || null,
  vetFeeLimit12000: getPricesFromQuote(quote, CoverLevel.VET_FEE_LIMIT_12000) || null,
});

export const getProductPrices = (
  quote: CurrentQuote,
  product: ProductType | undefined
): PriceSummary | undefined => {
  switch (product) {
    case ProductType.Vet_Fee_Limit_2000:
      return quote.price?.vetFeeLimit2000;
    case ProductType.Vet_Fee_Limit_3000:
      return quote.price?.vetFeeLimit3000;
    case ProductType.Vet_Fee_Limit_5000:
      return quote.price?.vetFeeLimit5000;
    case ProductType.Vet_Fee_Limit_7500:
      return quote.price?.vetFeeLimit7500;
    case ProductType.Vet_Fee_Limit_9000:
      return quote.price?.vetFeeLimit9000;
    case ProductType.Vet_Fee_Limit_12000:
      return quote.price?.vetFeeLimit12000;
    default:
      return null;
  }
};

export const getSelectedProductPrices = (quote: CurrentQuote): PriceSummary | undefined =>
  getProductPrices(quote, quote.quoteOptions.productType);

const currentQuoteSelector = (state: RootState): CurrentQuote => {
  const { quote, quoteSummaryOptions } = state;

  return {
    petInfos: quote?.petInfos ?? null,
    customerInfo: quote?.customerInfo ?? null,
    policyInfo: quote?.policyInfo ?? null,
    quoteOptions: quoteSummaryOptions,
    price: quote ? getAllPricesFromQuote(quote) : null,
    productId: quote?.productId ?? ProductId.DIRECT,
    selectedBundleCovers: quote ? getSelectedBundleCovers(quote) : [],
    confusedDeeplinkRefValue: quote?.confusedDeeplinkRefValue ?? '',
  };
};

/**
 * Provides the quote data and active quote options in an easily readable state
 */
export const useCurrentQuote = (): CurrentQuote =>
  useSelector(currentQuoteSelector, shallowEqual);

export const useUpdateQuoteOptions = (): ((update: Partial<QuoteOptions>) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (update: Partial<QuoteOptions>): void => {
      dispatch({ type: UPDATE_QUOTE_OPTIONS, update });
    },
    [dispatch]
  );
};
