import { formatNumericDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { trimEnd } from 'lodash';
import { formatInPounds } from 'helpers/poundsHelpers';
import { getVetFeeLimitFromProduct } from 'helpers/productHelpers';
import { capitaliseFirstCharacter } from 'helpers/stringHelpers';
import {
  CurrentQuote,
  getProductPrices,
  getSelectedProductPrices,
} from 'helpers/useCurrentQuote';
import { ProductType } from 'state/formData/quoteOptions';
import { ConfirmationQuote } from 'state/quote/confirmationQuote';
import { combinePetNames } from './dynamicPetNameHelpers';
import { CsPlaceholders } from './replaceCsPlaceholders';

export const quotePlaceholders: CsPlaceholders<CurrentQuote> = {
  petNames: {
    getSubstitutionText: (quote) => {
      const petNames = quote.petInfos?.map((pet) => trimEnd(pet.petName)) ?? [''];
      return combinePetNames(petNames);
    },
    isPii: false,
  },
  monthlyPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getSelectedProductPrices(quote)?.monthlyPrice.total),
    isPii: false,
  },
  monthlyTotalAmount: {
    getSubstitutionText: (quote) => {
      const monthlyAmount = getSelectedProductPrices(quote)?.monthlyPrice.total;
      return formatInPounds(monthlyAmount && monthlyAmount * 12);
    },
    isPii: false,
  },
  annualPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getSelectedProductPrices(quote)?.annualPrice.total),
    isPii: false,
  },
  customerEmail: {
    getSubstitutionText: (quote) => quote.customerInfo?.email ?? '',
    isPii: true,
  },
  customerFirstName: {
    getSubstitutionText: (quote) => quote.customerInfo?.firstName ?? '',
    isPii: true,
  },
  quoteReference: {
    getSubstitutionText: (quote) => quote.policyInfo?.quoteNumber ?? '',
    isPii: false,
  },
  quoteExpiryDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.quoteExpiryDate
        ? formatNumericDate(new Date(quote.policyInfo?.quoteExpiryDate))
        : '',
    isPii: false,
  },
  coverStartDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.coverStartDate
        ? formatNumericDate(new Date(quote.policyInfo?.coverStartDate))
        : '',
    isPii: false,
  },
  selectedCoverLevel: {
    getSubstitutionText: (quote) =>
      capitaliseFirstCharacter(quote.quoteOptions.productType ?? ''),
    isPii: false,
  },
  selectedCoverLimit: {
    getSubstitutionText: (quote) =>
      formatInPounds(getVetFeeLimitFromProduct(quote.quoteOptions.productType)) ?? '',
    isPii: false,
  },
  productBenefitPluralText: {
    getSubstitutionText: (quote) => {
      const multipetQuote = (quote.petInfos?.length || 0) > 1;
      return multipetQuote ? 'for each pet, per year' : 'each year';
    },
    isPii: false,
  },
  summaryOfNeedsPluralText: {
    getSubstitutionText: (quote) => {
      const multipetQuote = (quote.petInfos?.length || 0) > 1;
      return multipetQuote ? ' for each pet, per year' : '';
    },
    isPii: false,
  },
  additionalQuestionPluralText: {
    getSubstitutionText: (quote) => {
      const multipetQuote = (quote.petInfos?.length || 0) > 1;
      const petNames = quote.petInfos?.map((pet) => trimEnd(pet.petName)) ?? [''];
      return multipetQuote ? 'each pet,' : combinePetNames(petNames);
    },
    isPii: false,
  },
};

export const getQuotePlaceholdersWithProductOption = (
  product: ProductType
): CsPlaceholders<CurrentQuote> => ({
  ...quotePlaceholders,
  coverLevel: {
    getSubstitutionText: () => capitaliseFirstCharacter(product),
    isPii: false,
  },
  coverMonthlyPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getProductPrices(quote, product)?.monthlyPrice.total),
    isPii: false,
  },
  coverAnnualPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getProductPrices(quote, product)?.annualPrice.total),
    isPii: false,
  },
});

export const confirmationQuotePlaceholders: CsPlaceholders<ConfirmationQuote | null> = {
  customerEmail: {
    getSubstitutionText: (quote) => quote?.customerInfo?.email ?? 'your email address',
    isPii: true,
  },
};
