import Feature from '@rsa-digital/evo-shared-components/components/Feature';
import {
  gridItemPropsOverride,
  GridItemWrapper,
} from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';

export const StyledFeature = styled(Feature)`
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;

  && {
    margin-top: 0;
    margin-bottom: ${spacing(3)};

    ${GridItemWrapper} {
      ${gridItemPropsOverride({ desktop: 6.55, tabletLandscape: 6 })}
      margin-left: 0;
    }

    ${RichText} {
      & {
        font-weight: ${lightFontWeight};
      }
      h2 {
        margin: ${spacing(2)} 0;
        ${fonts.headingSmall}
      }

      p {
        ${fonts.paragraphLarge};
      }
      p :first-child {
        margin-top: 0;
      }
    }

    ${mediaQuery.tabletPortrait`
      && {
        margin-bottom: ${spacing(6)};
      }
    `}
  }
`;
