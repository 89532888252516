import { parseDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { Quote, QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { getFullMonths, getFullYears } from 'helpers/ageHelpers';
import { ProductId } from 'helpers/businessConstants';
import { petType_DOG } from 'helpers/referenceDataConstants';
import { CurrentQuote } from 'helpers/useCurrentQuote';

export const petAgeIsEqualToTwoMonths = (dateOfBirth: Date | undefined): boolean => {
  return dateOfBirth
    ? getFullYears(new Date(dateOfBirth)) === 0 &&
        getFullMonths(new Date(dateOfBirth)) === 2
    : false;
};

export const isDog = (petOnQuote: QuoteResponsePetInfo): boolean =>
  petOnQuote.petType === petType_DOG;

const petRequiresAdditionalQuestionsCTM = (petOnQuote: QuoteResponsePetInfo): boolean =>
  petAgeIsEqualToTwoMonths(parseDate(petOnQuote.dob));

const petRequiresAdditionalQuestionsConfused = (
  petOnQuote: QuoteResponsePetInfo
): boolean =>
  petAgeIsEqualToTwoMonths(parseDate(petOnQuote.dob)) ||
  petOnQuote.petType === petType_DOG;

export const isAggsProductId = (productId: ProductId): boolean =>
  productId !== ProductId.DIRECT;

export const isAggsQuote = (quote: CurrentQuote | Quote): boolean =>
  isAggsProductId(quote.productId);

export const isCTMQuote = (quote: CurrentQuote | Quote): boolean =>
  quote.productId === ProductId.COMPARE_THE_MARKET;

export const isConfusedQuote = (quote: CurrentQuote | Quote): boolean =>
  quote.productId === ProductId.CONFUSED;

export const shouldAggsQuoteShowAdditionalQuestions = (
  quote: Quote | CurrentQuote
): boolean =>
  (isCTMQuote(quote) &&
    (quote.petInfos?.some((petInfo) => petRequiresAdditionalQuestionsCTM(petInfo)) ??
      false)) ||
  (isConfusedQuote(quote) &&
    quote.petInfos?.length === 1 &&
    (quote.petInfos?.some((petInfo) => petRequiresAdditionalQuestionsConfused(petInfo)) ??
      false));

export const shouldAggsQuoteShowAdditionalQuestionsNonCTM = (
  quote: Quote | CurrentQuote
): boolean =>
  !isCTMQuote(quote) &&
  isConfusedQuote(quote) &&
  quote.petInfos?.length === 1 &&
  (quote.petInfos?.some((petInfo) => petRequiresAdditionalQuestionsConfused(petInfo)) ??
    false);
