import {
  catBreedType_PEDIGREE,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  gender_F,
  gender_M,
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { Pet } from 'state/formData/petsDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsPlaceholders } from './replaceCsPlaceholders';

export const useAboutYourPetPlaceholders = (): CsPlaceholders<Pet> => {
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];

  const getReadableMongrelSize = (mongrelSize: string): string => {
    switch (mongrelSize) {
      case mongrelSize_SMALL:
        return 'small';
      case mongrelSize_MEDIUM:
        return 'medium';
      case mongrelSize_LARGE:
        return 'large';
      default:
        return '';
    }
  };

  const getPetBreedName = (pet: Pet): string => {
    if (pet.petType === petType_DOG) {
      if (pet.dogBreedType === dogBreedType_MONGREL) return '';

      const dogBreed =
        pet.dogBreedType === dogBreedType_PEDIGREE
          ? pet.dogPedigreeBreedName
          : pet.dogCrossBreedName;

      return dogBreedsRefData.find((breed) => breed.value === dogBreed)?.name ?? '';
    }

    const catBreed =
      pet.catBreedType === catBreedType_PEDIGREE
        ? pet.catPedigreeBreedName
        : pet.catNonPedigreeBreedName;
    return catBreedsRefData.find((breed) => breed.value === catBreed)?.name ?? '';
  };

  const getNeuteredOrSpayed = (petGender: string): string => {
    switch (petGender) {
      case gender_F:
        return 'spayed';
      case gender_M:
        return 'neutered';
      default:
        return 'neutered or spayed';
    }
  };

  return {
    petName: {
      getSubstitutionText: (pet) => pet.petName,
      isPii: false,
    },
    petBreedName: {
      getSubstitutionText: (pet) => getPetBreedName(pet),
      isPii: false,
    },
    petMongrelSize: {
      getSubstitutionText: (pet) => getReadableMongrelSize(pet.mongrelSize),
      isPii: false,
    },
    neuteredOrSpayed: {
      getSubstitutionText: (pet) => getNeuteredOrSpayed(pet.petGender),
      isPii: false,
    },
  };
};

export const petNeuteredOrSpayedReplacer = (
  petGender: string,
  csString: string
): string => {
  let replaceString = 'spayed or neutered';
  if (petGender === gender_F) replaceString = 'spayed';
  if (petGender === gender_M) replaceString = 'neutered';
  return csString.replace(/({{neuteredOrSpayed}})/g, replaceString);
};
