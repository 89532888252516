export const QUOTE_UUID_SESSION_KEY = 'QUOTE_UUID';
export const QIP_UUID_SESSION_KEY = 'QUOTE_IN_PROGRESS_UUID';
export const QUOTE_OPTIONS_SESSION_KEY = 'QUOTE_OPTIONS';
export const TRANSACTION_ID_SESSION_KEY = 'TRANSACTION_ID';
export const ASSUMPTIONS_AGREEMENT_SESSION_KEY = 'ASSUMPTIONS_AGREEMENT';
export const RECAPTCHA_SKIP_KEY_SESSION_KEY = 'RECAPTCHA_SKIP_KEY_SESSION_KEY';
export const PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY =
  'PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY';
export const DISABLE_DATE_CHECKS_STORAGE_KEY = 'DISABLE_DATE_CHECKS';
export const CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY = 'CONFUSED_DEEPLINK_REF_VALUE';

// The keys included here will be cleared when the session expires or when the user completes the payment step.
export const sessionInProgressKeys = [
  QIP_UUID_SESSION_KEY,
  QUOTE_OPTIONS_SESSION_KEY,
  QUOTE_UUID_SESSION_KEY,
  TRANSACTION_ID_SESSION_KEY,
  ASSUMPTIONS_AGREEMENT_SESSION_KEY,
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
];

export const storeData = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    sessionStorage.setItem(key, value);
  }
};

export const retrieveData = (key: string): string | undefined => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    return sessionStorage.getItem(key) || undefined;
  }
  return undefined;
};

export const removeData = (key: string): void => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    sessionStorage.removeItem(key);
  }
};

export const removeSessionData = (): void => {
  sessionInProgressKeys.forEach((key) => removeData(key));
};
