import { ProductType, QuoteOptions } from 'state/formData/quoteOptions';
import { CsApplicableProducts } from 'types/contentStack';
import { CoverLimit } from './businessConstants';

// Note this *MUST* match the strings in the 'Product' global field in CS
export enum Product {
  Vet_Fee_Limit_2000 = 'Vet Fee Limit 2000',
  Vet_Fee_Limit_3000 = 'Vet Fee Limit 3000',
  Vet_Fee_Limit_5000 = 'Vet Fee Limit 5000',
  Vet_Fee_Limit_7500 = 'Vet Fee Limit 7500',
  Vet_Fee_Limit_9000 = 'Vet Fee Limit 9000',
  Vet_Fee_Limit_12000 = 'Vet Fee Limit 12000',
}

export const getProductFromProductType = (
  productType: ProductType | undefined
): Product | undefined => {
  switch (productType) {
    case ProductType.Vet_Fee_Limit_2000:
      return Product.Vet_Fee_Limit_2000;
    case ProductType.Vet_Fee_Limit_3000:
      return Product.Vet_Fee_Limit_3000;
    case ProductType.Vet_Fee_Limit_5000:
      return Product.Vet_Fee_Limit_5000;
    case ProductType.Vet_Fee_Limit_7500:
      return Product.Vet_Fee_Limit_7500;
    case ProductType.Vet_Fee_Limit_9000:
      return Product.Vet_Fee_Limit_9000;
    case ProductType.Vet_Fee_Limit_12000:
      return Product.Vet_Fee_Limit_12000;
    default:
      return undefined;
  }
};

export const appliesToSelection = (
  quoteOptions: QuoteOptions,
  csProductSelection: CsApplicableProducts
): boolean => {
  const product = getProductFromProductType(quoteOptions.productType);
  if (!product) {
    return false;
  }
  return csProductSelection.product_selector.includes(product);
};

export const getVetFeeLimitFromProduct = (
  product: ProductType | undefined
): CoverLimit | undefined => {
  switch (product) {
    case ProductType.Vet_Fee_Limit_2000:
      return CoverLimit.VET_FEE_LIMIT_2000;
    case ProductType.Vet_Fee_Limit_3000:
      return CoverLimit.VET_FEE_LIMIT_3000;
    case ProductType.Vet_Fee_Limit_5000:
      return CoverLimit.VET_FEE_LIMIT_5000;
    case ProductType.Vet_Fee_Limit_7500:
      return CoverLimit.VET_FEE_LIMIT_7500;
    case ProductType.Vet_Fee_Limit_9000:
      return CoverLimit.VET_FEE_LIMIT_9000;
    case ProductType.Vet_Fee_Limit_12000:
      return CoverLimit.VET_FEE_LIMIT_12000;
    default:
      return undefined;
  }
};
