import { getStartOfDay } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { ProductType } from 'state/formData/quoteOptions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import {
  CsAsset,
  CsCashbackBanner,
  CsCashbackPanelVariant,
  CsIcon,
  CsOffer,
  CsPromoCodeBanner,
} from 'types/contentStack';
import { ProductId } from './businessConstants';
import { processImageAsset } from './csTypeProcessors';
import { Product } from './productHelpers';
import { CurrentQuote, useCurrentQuote } from './useCurrentQuote';

export enum ActiveProductId {
  Direct = 'Direct',
  GoCompare = 'GoCompare',
  CTM = 'Compare The Market',
  MSM = 'Money Super Market',
  Confused = 'Confused',
}

type CsPetPricingOverviewPanel = {
  csPetPricingOverviewPanel: {
    promo_banners: {
      cashback_banner: CsCashbackBanner[];
      promo_code_banner: CsPromoCodeBanner[];
    };
  };
};

type CsPetOfferPanel = {
  csPetOfferPanel: {
    panel_icon: [CsIcon];
    panel_content_variations: CsCashbackPanelVariant[];
  };
  csPetOffers: {
    quote_summary_offers: CsOffer[];
    price_panel_offers: CsOffer[];
    quote_confirmation_offers: CsOffer[];
  };
};

const query = graphql`
  query {
    csPetPricingOverviewPanel {
      promo_banners {
        cashback_banner {
          cashback_rich_text
          cashback_icon {
            ...CsAsset
          }
          active_routes_to_quote
          active_cover_levels {
            product_selector
          }
        }
        promo_code_banner {
          promo_code_rich_text
          promo_code_icon {
            ...CsAsset
          }
          active_cover_levels {
            product_selector
          }
          promo_code
        }
      }
    }
    csPetOfferPanel {
      panel_content_variations {
        active_routes
        active_cover_levels
        heading
        offer_end_date
        offer_start_date
        panel_text
        confirmation_text
      }
    }
    csPetOffers {
      quote_summary_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_types
        offer_start_date
        offer_end_date
      }
      price_panel_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_types
        offer_start_date
        offer_end_date
        voucher_amounts {
          ... on cs__cover_level_bonus_points {
            vet_fee_limit_5000
            vet_fee_limit_7500
            vet_fee_limit_9000
            vet_fee_limit_12000
          }
        }
        promocode
      }
      quote_confirmation_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_types
        offer_start_date
        offer_end_date
        promocode
      }
    }
  }
`;

const useQuery = (): CsPetPricingOverviewPanel & CsPetOfferPanel =>
  useStaticQuery<CsPetPricingOverviewPanel & CsPetOfferPanel>(query);

export const isOfferValidForProductId = (
  productId: ProductId,
  activeProductIds: ActiveProductId[]
): boolean => {
  switch (productId) {
    case ProductId.DIRECT:
      return activeProductIds.includes(ActiveProductId.Direct);
    case ProductId.GO_COMPARE:
      return activeProductIds.includes(ActiveProductId.GoCompare);
    case ProductId.COMPARE_THE_MARKET:
      return activeProductIds.includes(ActiveProductId.CTM);
    case ProductId.MONEY_SUPERMARKET:
      return activeProductIds.includes(ActiveProductId.MSM);
    case ProductId.CONFUSED:
      return activeProductIds.includes(ActiveProductId.Confused);
    default:
      return false;
  }
};

export const isCoverLevelActive = (
  productType: ProductType,
  activeCoverLevels: Product[]
): boolean => {
  switch (productType) {
    case ProductType.Vet_Fee_Limit_2000:
      return activeCoverLevels.includes(Product.Vet_Fee_Limit_2000);
    case ProductType.Vet_Fee_Limit_3000:
      return activeCoverLevels.includes(Product.Vet_Fee_Limit_3000);
    case ProductType.Vet_Fee_Limit_5000:
      return activeCoverLevels.includes(Product.Vet_Fee_Limit_5000);
    case ProductType.Vet_Fee_Limit_7500:
      return activeCoverLevels.includes(Product.Vet_Fee_Limit_7500);
    case ProductType.Vet_Fee_Limit_9000:
      return activeCoverLevels.includes(Product.Vet_Fee_Limit_9000);
    case ProductType.Vet_Fee_Limit_12000:
      return activeCoverLevels.includes(Product.Vet_Fee_Limit_12000);
    default:
      return false;
  }
};

interface PetInfo {
  petType: string;
}

export const isActivePetType = (
  activeQuote: CurrentQuote,
  activePetType: string[]
): boolean => {
  const petTypes: string[] = [
    ...new Set(activeQuote?.petInfos?.map((petInfo: PetInfo) => petInfo.petType)),
  ];
  const offerPetTypes = activePetType.map((petType) => {
    switch (petType) {
      case 'Cat':
        return 'NWA_PET_C';
      case 'Dog':
        return 'NWA_PET_D';
      default:
        return '';
    }
  });

  return offerPetTypes.some((item) => petTypes.includes(item));
};

export const offerIsInActiveRange = (
  offerStartDate: string | undefined,
  offerEndDate: string | undefined
): boolean => {
  const startOfToday = getStartOfDay(new Date());

  const offerStarted = !offerStartDate || startOfToday >= new Date(offerStartDate);
  const offerNotExpired = !offerEndDate || startOfToday <= new Date(offerEndDate);

  return offerStarted && offerNotExpired;
};

export const isValidOfferPanel = (
  productId: ProductId,
  activeProductIds: ActiveProductId[],
  productType: ProductType,
  activeCoverLevels: Product[],
  startDate: string | undefined,
  endDate: string
): boolean => {
  return (
    isOfferValidForProductId(productId, activeProductIds) &&
    isCoverLevelActive(productType, activeCoverLevels) &&
    offerIsInActiveRange(startDate, endDate)
  );
};

// Uses the current quote to find the relevant offer panel if any are applicable
export const useValidOfferPanel = (): CsCashbackPanelVariant | undefined => {
  const {
    csPetOfferPanel: { panel_content_variations },
  } = useQuery();
  const quote = useCurrentQuote();

  const offerPanel = panel_content_variations.find(
    ({ active_routes, active_cover_levels, offer_start_date, offer_end_date }) =>
      isOfferValidForProductId(quote.productId, active_routes) &&
      offerIsInActiveRange(offer_start_date, offer_end_date) &&
      isCoverLevelActive(
        quote.quoteOptions.productType as ProductType,
        active_cover_levels
      )
  );

  return offerPanel ?? undefined;
};

export const useCashbackDiscount = (): CsCashbackBanner | undefined => {
  const {
    csPetPricingOverviewPanel: {
      promo_banners: { cashback_banner },
    },
  } = useQuery();
  const quote = useCurrentQuote();

  return cashback_banner.find(
    (banner) =>
      isOfferValidForProductId(quote.productId, banner.active_routes_to_quote) &&
      isCoverLevelActive(
        quote.quoteOptions.productType as ProductType,
        banner.active_cover_levels.product_selector
      )
  );
};

export const usePromoCodeDiscount = (): CsPromoCodeBanner | undefined => {
  const {
    csPetPricingOverviewPanel: {
      promo_banners: { promo_code_banner },
    },
  } = useQuery();
  const quote = useCurrentQuote();

  const isPromoCodeActive = (promoCode: string | undefined): boolean =>
    !!promoCode && promoCode === quote.policyInfo?.promotionalCode;

  return promo_code_banner.find(
    (banner) =>
      isPromoCodeActive(banner.promo_code) &&
      isCoverLevelActive(
        quote.quoteOptions.productType as ProductType,
        banner.active_cover_levels.product_selector
      )
  );
};

export const isValidPromocode = (
  quote: CurrentQuote,
  promoCode: string | undefined
): boolean => {
  if (quote?.policyInfo?.promotionalCode === '' || !promoCode) return true;
  return quote?.policyInfo?.promotionalCode === promoCode;
};

export const useValidPricePanelOffers = (): CsOffer[] | undefined => {
  const {
    csPetOffers: { price_panel_offers },
  } = useQuery();
  const quote = useCurrentQuote();

  const offers = price_panel_offers.filter(
    ({
      active_routes_to_quote,
      active_cover_levels,
      offer_start_date,
      offer_end_date,
      promocode,
    }) =>
      isOfferValidForProductId(quote.productId, active_routes_to_quote) &&
      isCoverLevelActive(
        quote.quoteOptions.productType as ProductType,
        active_cover_levels
      ) &&
      offerIsInActiveRange(offer_start_date, offer_end_date) &&
      isValidPromocode(quote, promocode)
  );

  return offers ?? undefined;
};

export const useValidQuoteSummaryOffers = (): CsOffer[] | undefined => {
  const {
    csPetOffers: { quote_summary_offers },
  } = useQuery();
  const quote = useCurrentQuote();

  const offers = quote_summary_offers.filter(
    ({
      active_routes_to_quote,
      active_cover_levels,
      active_pet_types,
      offer_start_date,
      offer_end_date,
      promocode,
    }) =>
      isOfferValidForProductId(quote.productId, active_routes_to_quote) &&
      isCoverLevelActive(
        quote.quoteOptions.productType as ProductType,
        active_cover_levels
      ) &&
      offerIsInActiveRange(offer_start_date, offer_end_date) &&
      isActivePetType(quote, active_pet_types) &&
      isValidPromocode(quote, promocode)
  );

  return offers ?? undefined;
};

export const useValidConfirmationOffers = (): CsOffer[] | undefined => {
  const {
    csPetOffers: { quote_confirmation_offers },
  } = useQuery();
  const [quote] = useConfirmationQuote();

  if (quote) {
    const offers = quote_confirmation_offers.filter(
      ({
        active_routes_to_quote,
        active_cover_levels,
        active_pet_types,
        offer_start_date,
        offer_end_date,
      }) =>
        isOfferValidForProductId(quote.productId, active_routes_to_quote) &&
        isCoverLevelActive(
          quote.quoteOptions.productType as ProductType,
          active_cover_levels
        ) &&
        offerIsInActiveRange(offer_start_date, offer_end_date) &&
        isActivePetType(quote, active_pet_types)
    );

    return offers ?? undefined;
  }

  return undefined;
};

export const displayMultipetDiscount = (quote: CurrentQuote): boolean => {
  const coverType = quote?.quoteOptions?.productType;
  if (coverType === undefined) {
    return false;
  }

  return (quote.petInfos?.length || 0) > 1;
};

// This is not currently used, but may be in future - current agreed upon approach is to have it show if an icon is found in CS, see EP-514 for details
export const displayOnlineDiscount = (onlineDiscountIcon: CsAsset | null): boolean => {
  const onlineDiscountLogo = processImageAsset(onlineDiscountIcon);
  return onlineDiscountLogo !== undefined;
};

export const useQuoteHasDiscounts = (quote: CurrentQuote): boolean => {
  const cashbackBanner = useCashbackDiscount();
  const promoCodeBanner = usePromoCodeDiscount();
  const isCashbackOfferActive = !!useValidOfferPanel();
  const pricePanelOffers = useValidPricePanelOffers();
  const displayPricePanelOffers = pricePanelOffers ? pricePanelOffers.length > 0 : false;

  return (
    !!promoCodeBanner ||
    (isCashbackOfferActive && !!cashbackBanner) ||
    displayPricePanelOffers ||
    displayMultipetDiscount(quote)
  );
};
