import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { gridItemPropsOverride } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import HeroBanner from 'components/Header/HeroBanner';
import { Hero } from 'components/Header/HeroBanner/styles';
import { hideOnPrint } from 'helpers/cssPrintHelpers';

export const StyledHeroBanner = styled(HeroBanner)`
  ${Grid} > ${GridItem} {
      ${gridItemPropsOverride({ desktop: 9.35, tabletLandscape: 8 })}

  ${Hero} {
    margin-bottom: ${spacing(2)};
    text-align: left;

    ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(3)};
    `}
  }
`;

export const BannerContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /** 
  This needs to be less than 999 to ensure it does not overlap modals.
  (Otherwise an issue occurs with the session timeout modal on mobile)
  */
  z-index: 998;

  ${hideOnPrint}
`;

export const FooterGridContainer = styled(GridContainer)`
  padding-right: 0;
  padding-left: 0;
`;
