import { localDateToUtcDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MarketingProduct } from 'helpers/businessConstants';
import { RootState } from 'state/createStore';

export const UPDATE_POLICY_DETAILS = 'UPDATE_POLICY_DETAILS';

export type MarketingConsent = {
  isConsentGiven: boolean;
  marketingProduct: MarketingProduct;
};

export type MarketingConsents = {
  johnLewis: boolean;
  waitrose: boolean;
  johnLewisFinancialServices: boolean;
};

export type MarketingCommunication = {
  marketingConsentFlag?: boolean;
  marketingConsent?: MarketingConsent[];
};

export type PolicyDetails = {
  coverStartDate: string;
  marketingConsentFlag?: boolean;
  marketingConsents: MarketingConsents;
};

export const initialMarketingConsents: MarketingConsents = {
  johnLewis: false,
  waitrose: false,
  johnLewisFinancialServices: false,
};

export const initialPolicyDetails: PolicyDetails = {
  coverStartDate: localDateToUtcDate(new Date()).toISOString(),
  marketingConsentFlag: undefined,
  marketingConsents: initialMarketingConsents,
};

export type UpdatePolicyDetailsAction = {
  type: typeof UPDATE_POLICY_DETAILS;
  update: PolicyDetails;
};

export const usePolicyDetails = (): [
  PolicyDetails,
  (update: Partial<PolicyDetails>) => void
] => {
  const policyDetails = useSelector((state: RootState) => state.policyDetails);
  const dispatch = useDispatch();

  const updatePolicyDetails = useCallback(
    (update: Partial<PolicyDetails>): void => {
      dispatch({ type: UPDATE_POLICY_DETAILS, update });
    },
    [dispatch]
  );

  return [policyDetails, updatePolicyDetails];
};
