import Accordion from '@rsa-digital/evo-shared-components/components/Accordion';
import { SectionTitle } from '@rsa-digital/evo-shared-components/components/Accordion/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import Table from 'components/Table';

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};
  margin-bottom: ${spacing(2)};
`;

export const ModalGridItem = styled(GridItem)`
  /* This relies on the modal order being a heading followed by blocks. So this selector
  targets the first block after the heading, and changes the spacing if it's a rich text */
  & > ${RichText}:nth-child(2) {
    margin-top: ${spacing(2)};
  }

  padding: 0 ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    padding: 0 ${spacing(7)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: 0;
  `}
`;

const verticalMargin = css`
  margin: ${spacing(4)} 0;
`;

export const RichTextWithVerticalMargin = styled(RichText)`
  ${verticalMargin}

  & h3 {
    ${fonts.headingXSmall}
  }
`;

export const TableWithVerticalMargin = styled(Table)`
  ${verticalMargin}
`;

export const AccordionWithSmallHeadings = styled(Accordion)`
  ${SectionTitle} {
    ${fonts.headingSmall};
  }
`;
