import { petType_DOG } from 'helpers/referenceDataConstants';
import { Pet } from 'state/formData/petsDetails';

// reveals once the pet type is chosen (cat / dog)
export const showEligibilityQuestions = (petDetails: Pet): boolean =>
  !!petDetails.petType && petDetails.petName !== '';

// reveals after the eligibility questions have been agreed to
export const showPetGoodHealthQuestions = (petDetails: Pet): boolean =>
  showEligibilityQuestions(petDetails) &&
  petDetails.eligibilityConditionsAgreement === true;

// reveals after the eligibility questions have been agreed to and good health
export const showGeneralPetQuestions = (petDetails: Pet): boolean =>
  showEligibilityQuestions(petDetails) &&
  petDetails.eligibilityConditionsAgreement === true &&
  petDetails.petInGoodHealth === true;

// reveals after the above conditions are met and all the general pet questions have been answered
export const showPetBreedQuestions = (petDetails: Pet): boolean =>
  showGeneralPetQuestions(petDetails) &&
  !!petDetails.petGender &&
  !!petDetails.petSpayed &&
  (petDetails.petType === petType_DOG || !!petDetails.petChipped) &&
  petDetails.petCost !== undefined &&
  petDetails.petDob.day !== '' &&
  petDetails.petDob.month !== '' &&
  petDetails.petDob.year !== '';

// reveals after the conditions above are met and a pet breed (either a cat or dog breed) has been chosen
export const showAdditionalPetBanner = (petDetails: Pet): boolean =>
  showPetBreedQuestions(petDetails) &&
  (petDetails.catPedigreeBreedName !== '' ||
    petDetails.catNonPedigreeBreedName !== '' ||
    petDetails.dogPedigreeBreedName !== '' ||
    petDetails.dogCrossBreedName !== '' ||
    petDetails.mongrelSize !== '');
