import { MarketingProduct } from 'helpers/businessConstants';
import {
  initialMarketingConsents,
  MarketingConsent,
  MarketingConsents,
  PolicyDetails,
} from 'state/formData/policyDetails';
import { QuotePolicyInfo } from '../quoteRequest';

export const mapMarketingConsent = (
  marketingConsent?: MarketingConsent[]
): MarketingConsents => {
  return {
    johnLewis: !!marketingConsent?.find(
      (consent) => consent.marketingProduct === MarketingProduct.JOHN_LEWIS
    )?.isConsentGiven,
    waitrose: !!marketingConsent?.find(
      (consent) => consent.marketingProduct === MarketingProduct.WAITROSE
    )?.isConsentGiven,
    johnLewisFinancialServices: !!marketingConsent?.find(
      (consent) =>
        consent.marketingProduct === MarketingProduct.JOHN_LEWIS_FINANCIAL_SERVICES
    )?.isConsentGiven,
  };
};

const mapPolicyDetails = (policyInfo: QuotePolicyInfo): PolicyDetails => ({
  coverStartDate: policyInfo.coverStartDate,
  marketingConsents:
    policyInfo.marketingCommunication === null
      ? initialMarketingConsents
      : mapMarketingConsent(policyInfo.marketingCommunication.marketingConsent),
  marketingConsentFlag: policyInfo.marketingCommunication?.marketingConsentFlag,
});

export default mapPolicyDetails;
