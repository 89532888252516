import Intro from '@rsa-digital/evo-shared-components/components/Intro';
import { graphql } from 'gatsby';
import React from 'react';

type IntroBlockProps = {
  intro: {
    heading: string;
    subhead: string;
  };
};

export const query = graphql`
  fragment IntroBlockError on cs__error_page_blocks {
    intro {
      heading
      subhead
    }
  }
`;

const IntroBlock: React.FC<IntroBlockProps> = ({ intro }) => (
  <Intro heading={intro.heading} subhead={intro.subhead} data-cy="Intro" />
);

export default React.memo(IntroBlock);
