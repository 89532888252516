import {
  initialLookupValue,
  LookupValue,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { generateKey } from 'helpers/arrayHelpers';

export enum ProductType {
  Vet_Fee_Limit_2000 = 'vet_fee_limit_2000',
  Vet_Fee_Limit_3000 = 'vet_fee_limit_3000',
  Vet_Fee_Limit_5000 = 'vet_fee_limit_5000',
  Vet_Fee_Limit_7500 = 'vet_fee_limit_7500',
  Vet_Fee_Limit_9000 = 'vet_fee_limit_9000',
  Vet_Fee_Limit_12000 = 'vet_fee_limit_12000',
}

export type ExcessPercentageConfirmation = {
  key: number;
  hasConfirmedExcessPercentage: boolean | undefined;
};

export const initialExcessPercentageConfirmation = {
  hasConfirmedExcessPercentage: undefined,
};

export type PromoCodeLookup = LookupValue<string, void>;

export type QuoteOptions = {
  productType: ProductType | undefined;
  isAnnualPayment: boolean;
  partnershipCard: boolean | undefined;
  hasConfirmedVetFeesLimit: boolean | undefined;
  excessPercentageConfirmations: ExcessPercentageConfirmation[];
  promoCode: PromoCodeLookup;
};

export const initialPromoCodeLookup: PromoCodeLookup = initialLookupValue('');

export const initialQuoteOptions: QuoteOptions = {
  productType: undefined,
  isAnnualPayment: false,
  partnershipCard: undefined,
  hasConfirmedVetFeesLimit: undefined,
  excessPercentageConfirmations: [
    { ...initialExcessPercentageConfirmation, key: generateKey([]) },
  ],
  promoCode: initialPromoCodeLookup,
};

export const isPromoCodeBlank = (quoteOptions: QuoteOptions): boolean =>
  !quoteOptions.promoCode.lookupKey;

export const UPDATE_QUOTE_OPTIONS = 'UPDATE_QUOTE_OPTIONS';

export type UpdateQuoteOptionsAction = {
  type: typeof UPDATE_QUOTE_OPTIONS;
  update: QuoteOptions;
};
