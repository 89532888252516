import { isAggsProductId } from 'businessLogic/aggregators';
import { MarketingProduct, ProductId } from 'helpers/businessConstants';
import { getDateOnlyIsoString } from 'helpers/dateHelpers';
import { conditionalValue } from 'helpers/mappingHelpers';
import { CustomerDetails } from 'state/formData/customerDetails';
import { JointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import {
  MarketingCommunication,
  MarketingConsent,
  MarketingConsents,
  PolicyDetails,
} from 'state/formData/policyDetails';
import mapJointPolicyHolder from './mapJointPolicyHolder';
import { QuotePolicyInfo } from '../quoteRequest';

const consentNotGivenMarketingConsents: MarketingConsent[] = [
  { isConsentGiven: false, marketingProduct: MarketingProduct.JOHN_LEWIS },
  { isConsentGiven: false, marketingProduct: MarketingProduct.WAITROSE },
  {
    isConsentGiven: false,
    marketingProduct: MarketingProduct.JOHN_LEWIS_FINANCIAL_SERVICES,
  },
];

export const mapMarketingConsents = (
  marketingConsents: MarketingConsents
): MarketingConsent[] => [
  {
    isConsentGiven: marketingConsents.johnLewis,
    marketingProduct: MarketingProduct.JOHN_LEWIS,
  },
  {
    isConsentGiven: marketingConsents.waitrose,
    marketingProduct: MarketingProduct.WAITROSE,
  },
  {
    isConsentGiven: marketingConsents.johnLewisFinancialServices,
    marketingProduct: MarketingProduct.JOHN_LEWIS_FINANCIAL_SERVICES,
  },
];

export const getMarketingCommunication = (
  marketingCommunication: MarketingCommunication
): MarketingCommunication | null =>
  marketingCommunication.marketingConsentFlag !== undefined
    ? {
        marketingConsentFlag: marketingCommunication.marketingConsentFlag,
        marketingConsent: marketingCommunication.marketingConsentFlag
          ? marketingCommunication.marketingConsent
          : consentNotGivenMarketingConsents,
      }
    : null;

const mapPolicyInfo = (
  customerDetails: CustomerDetails,
  policyDetails: PolicyDetails,
  jointPolicyHolder: JointPolicyholderDetails,
  storedQuotePolicyInfo?: QuotePolicyInfo,
  storedQuoteProductId?: ProductId
): QuotePolicyInfo => {
  const storedQuoteIsAggsQuote =
    !!storedQuoteProductId && isAggsProductId(storedQuoteProductId);

  return {
    coverStartDate: getDateOnlyIsoString(policyDetails.coverStartDate),
    numberPetInHousehold: customerDetails.numberOfPetsInHousehold,
    includeJoinPolicyHolder: jointPolicyHolder.includeJointPolicyholder,
    joinPolicyHolder: conditionalValue(
      jointPolicyHolder.includeJointPolicyholder,
      mapJointPolicyHolder(jointPolicyHolder)
    ),
    quoteNumber: storedQuotePolicyInfo?.quoteNumber || null,
    quoteStartDate: storedQuotePolicyInfo?.quoteStartDate,
    contactable: null,
    promotionalCode:
      storedQuotePolicyInfo && !storedQuoteIsAggsQuote
        ? storedQuotePolicyInfo.promotionalCode
        : undefined,
    quoteExpiryDate: storedQuotePolicyInfo?.quoteExpiryDate,
    marketingCommunication: getMarketingCommunication({
      marketingConsent: mapMarketingConsents(policyDetails.marketingConsents),
      marketingConsentFlag: policyDetails.marketingConsentFlag,
    }),
  };
};

export default mapPolicyInfo;
