import { useEffect, useState } from 'react';
import { ProductType } from 'state/formData/quoteOptions';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import { displayMultipetDiscount } from './discountHelpers';
import {
  getCoverTypeLabelFromQuoteOptions,
  getPetBreedInReadableForm,
  getReadableCoPaymentExcessAmount,
  getReadableExcessAmount,
  getReadablePetBreedType,
  trackEvent,
  TrackingEvent,
  userHasSelectedCover,
} from './eventTracking';
import { getGeneralPageTrackingData } from './pageTracking';
import { petType_DOG } from './referenceDataConstants';
import {
  CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
  retrieveData,
} from './sessionStorageHelpers';
import {
  CurrentQuote,
  getProductPrices,
  getSelectedProductPrices,
} from './useCurrentQuote';

const TAX_RATE = 20;

type EcommerceProduct = {
  id: string; // cover type
  name?: string; // cover type
  variant?: string; // number of pets
  category?: string; // pet type in csv
  brand?: string; // breed name in csv
  quantity?: number; // Will always be 1
  price?: number; // annual price
  dimension13?: string; // breed type in csv
  dimension14?: string; // excess for each pet in csv
  dimension25?: string; // co payment excess for each pet in csv
};

export type EcommerceEvent = TrackingEvent & {
  ecommerce?: {
    currencyCode: string;
    checkout?: {
      actionField: {
        step: number; // Step in journey
      };
      products: EcommerceProduct[];
    };
    purchase?: {
      actionField: {
        id: string; // Transaction ID
        affiliation?: string; // Product and purchase type
        revenue?: number; // Total revenue
        tax?: number; // Tax rate
        coupon?: string; // Discount code
      };
      products: EcommerceProduct[];
    };
  };
};

const getProducts = (
  quote: CurrentQuote | null,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: ProductType
): EcommerceProduct[] => {
  if (!quote?.petInfos) {
    return [];
  }

  const coverType = productType ?? getCoverTypeLabelFromQuoteOptions(quote.quoteOptions);

  const product: EcommerceProduct = {
    id: coverType, // cover type
    name: coverType, // also cover type
    variant: `${quote.petInfos.length}`, // number of pets
    brand: quote.petInfos
      .map((petInfo) =>
        getPetBreedInReadableForm(petInfo, catBreedsRefData, dogBreedsRefData)
      )
      .join(','), // pet breed name in csv
    quantity: 1, // will always be 1
    category: quote.petInfos
      .map((petInfo) => (petInfo.petType === petType_DOG ? 'Dog' : 'Cat'))
      .join(','), // "Cat" or "Dog" in csv
    dimension13: quote.petInfos
      .map((petInfo) => getReadablePetBreedType(petInfo.petBreedType))
      .join(','), // pet breed type in csv
  };

  // we need the 'Or productType' here so this data will also come through when the checkout event is fired from a product being selected on QS
  if (userHasSelectedCover(quote) || productType) {
    product.price = productType
      ? getProductPrices(quote, productType)?.annualPrice.total
      : getSelectedProductPrices(quote)?.annualPrice.total; // total premium of policy
    product.dimension14 = quote.petInfos
      .map((petInfo) => getReadableExcessAmount(petInfo.voluntaryExcessAmount))
      .join(','); // pet excess in csv
    product.dimension25 = quote.petInfos
      .map((petInfo) =>
        getReadableCoPaymentExcessAmount(petInfo.voluntaryExcessPercentage)
      )
      .join(','); // pet copayment excess in csv
  }

  return [product];
};

export const trackCheckoutEvent = (
  step: number,
  quote: CurrentQuote | null,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: ProductType
): void => {
  const event: EcommerceEvent = {
    event: 'eec.checkout',
    ...getGeneralPageTrackingData(quote, catBreedsRefData, dogBreedsRefData, productType),
    ecommerce: {
      currencyCode: 'GBP',
      checkout: {
        actionField: {
          step,
        },
        products: getProducts(quote, catBreedsRefData, dogBreedsRefData, productType),
      },
    },
  };

  trackEvent(event);
};

const getDiscountList = (quote: CurrentQuote): string => {
  const promocode = quote.policyInfo?.promotionalCode;
  const discounts = [
    displayMultipetDiscount(quote) ? 'multipet' : null,
    promocode ? `promocode: ${promocode}` : null,
  ];

  const activeDiscounts = discounts.filter((discount) => !!discount);

  return activeDiscounts.length > 0 ? activeDiscounts.join(', ') : 'not set';
};

export const trackPurchaseEvent = (
  quote: CurrentQuote,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[]
): void => {
  const confusedRef = retrieveData(CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY);
  const event: EcommerceEvent = {
    event: 'eec.purchase',
    ...getGeneralPageTrackingData(quote, catBreedsRefData, dogBreedsRefData),
    confusedDeeplinkRefValue: confusedRef ?? '',
    ecommerce: {
      currencyCode: 'GBP',
      purchase: {
        actionField: {
          id: quote.policyInfo?.quoteNumber || '',
          affiliation: 'Quote and buy',
          revenue: getSelectedProductPrices(quote)?.annualPrice.total,
          tax: TAX_RATE,
          coupon: getDiscountList(quote),
        },
        products: getProducts(quote, catBreedsRefData, dogBreedsRefData),
      },
    },
  };

  trackEvent(event);
};

// Hook to track once when the quote loads
export const useCheckoutTracking = (
  step: number,
  quote: CurrentQuote | null,
  requireQuote: boolean
): void => {
  const [tracked, setTracked] = useState(false);

  const EMPTY: never[] = [];

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? EMPTY;
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? EMPTY;

  useEffect(() => {
    if (!tracked && (requireQuote ? !!quote?.customerInfo : true)) {
      trackCheckoutEvent(step, quote, catBreedsRefData, dogBreedsRefData);
      setTracked(true);
    }
  }, [quote, requireQuote, step, tracked, catBreedsRefData, dogBreedsRefData]);
};
