import escape from 'lodash/escape';
import { Question } from 'types/forms';

export type CsPlaceholders<T> = {
  [variable: string]: { getSubstitutionText: (object: T) => string; isPii: boolean };
};

export const noOpPlaceholderReplacer = (csString: string): string => csString;

const wrapInStrongTags = (text: string): string => {
  return `<strong>${text}</strong>`;
};

const getSubstitution = <T>(
  csPlaceholders: CsPlaceholders<T>,
  data: T,
  match: string
): { substitutionText: string; isPii: boolean } => {
  const matchedPlaceholder = match.substring(2, match.length - 2); // trim the curly braces
  const placeholderInfo = csPlaceholders[matchedPlaceholder];
  if (!placeholderInfo) {
    return {
      substitutionText: match,
      isPii: false,
    };
  }
  return {
    substitutionText: placeholderInfo.getSubstitutionText(data),
    isPii: placeholderInfo.isPii,
  };
};

/**
 * A function to replace variables set in ContentStack with values from an object returned from the api.
 * Variables in ContentStack are given by using double curly brackets e.g. {{Variable}}.
 * The corresponding variables are defined in the csPlaceholders object.
 *
 * @param csPlaceholders - the mappings from placeholder text to transformed data
 * @param data - the object containing the values that will replace the placeholders
 * @param allowPii - (optional, defaults so false) whether to allow PII placeholders (if true, a
 * data-pii-mask attribute should be added by the caller)
 * * data-pii-mask attribute changed to data-cs-mask)
 */
export const replacePlaceholdersPlainText = <T>(
  csPlaceholders: CsPlaceholders<T>,
  data: T,
  allowPii?: boolean
) => (csPlainTextString: string): string =>
  csPlainTextString.replace(/({{)\w+(}})/g, (match) => {
    const substitution = getSubstitution(csPlaceholders, data, match);
    return !substitution.isPii || allowPii ? substitution.substitutionText : match;
  });

/**
 * A function to replace variables set in ContentStack with values from an object returned from the api.
 * Variables in ContentStack are given by using double curly brackets e.g. {{Variable}}.
 * The corresponding variables are defined in the csPlaceholders object.
 *
 * Placeholder values are escaped to be used in RichText, and PII information is automatically masked.
 *
 * @param csPlaceholders - the mappings from placeholder text to transformed data
 * @param data - the object containing the values that will replace the placeholders
 */
export const replacePlaceholdersRichText = <T>(
  csPlaceholders: CsPlaceholders<T>,
  data: T,
  makeSubstitutionBold?: boolean
) => (csRichTextString: string): string =>
  csRichTextString.replace(/({{)\w+(}})/g, (match) => {
    const substitution = getSubstitution(csPlaceholders, data, match);
    const encodedSubstitutionText = escape(substitution.substitutionText);
    const formattedSubstitutionText = makeSubstitutionBold
      ? wrapInStrongTags(encodedSubstitutionText)
      : encodedSubstitutionText;
    return substitution.isPii
      ? `<span data-cs-mask="true">${formattedSubstitutionText}</span>`
      : formattedSubstitutionText;
  });

export const replacePlaceholdersQuestion = (
  placeholderReplacer: (string: string) => string
) => <Q extends Question>(question: Q): Q => ({
  ...question,
  questionText: placeholderReplacer(question.questionText),
});
