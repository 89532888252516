import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: ${spacing(8)};

  && > *:last-child {
    margin-bottom: 0;
  }

  ${mediaQuery.tabletPortrait`
    && > * {
      margin-bottom: 0;
    }
  `}
`;

export const Error404Container = styled.div`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(8)};
`;

export const Subheading = styled.p`
  & {
    text-align: center;
  }

  ${fonts.paragraphLarge}
`;
