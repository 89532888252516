import React from 'react';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const dataDomainScriptProduction = 'c101cf31-dddb-474b-8a4e-2cfc53d7f22b';
const dataDomainScriptTest = 'c101cf31-dddb-474b-8a4e-2cfc53d7f22b-test';

const oneTrustScripts: JSX.Element[] = [
  <script
    src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
    type="text/javascript"
    data-domain-script={
      analyticsEnv === 'production' ? dataDomainScriptProduction : dataDomainScriptTest
    }
    key="oneTrustScripts"
  />,
  <script type="text/javascript" key="optanonWrapper">
    {'function OptanonWrapper() {}'}
  </script>,
];

export default oneTrustScripts;
