import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as reduxCreateStore,
  Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { ErrorAction } from './error/actions';
import errorReducer from './error/reducer';
import { UpdateAdditionalQuestionsShownAction } from './formData/additionalQuestionsShown';
import additionalQuestionsShownReducer from './formData/additionalQuestionsShownReducer';
import { UpdateAssumptionsAgreementAction } from './formData/assumptionsAgreement';
import assumptionsAgreementReducer from './formData/assumptionsAgreementReducer';
import assumptionsAgreementSaga from './formData/assumptionsAgreementSaga';
import { UpdateCustomerDetailsAction } from './formData/customerDetails';
import { customerDetailsReducer } from './formData/customerDetailsReducer';
import { UpdateJointPolicyholderDetailsAction } from './formData/jointPolicyholderDetails';
import { jointPolicyholderDetailsReducer } from './formData/jointPolicyholderReducer';
import { UpdatePetsDetailsAction } from './formData/petsDetails';
import { petsDetailsReducer } from './formData/petsDetailsReducer';
import { UpdatePolicyDetailsAction } from './formData/policyDetails';
import { policyDetailsReducer } from './formData/policyDetailsReducer';
import { UpdateQuoteInProgressAction } from './formData/quoteInProgress';
import quoteInProgressSaga from './formData/quoteInProgressSaga';
import { UpdateQuoteOptionsAction } from './formData/quoteOptions';
import { quoteOptionsReducer } from './formData/quoteOptionsReducer';
import quoteOptionsSaga from './formData/quoteOptionsSaga';
import { UpdateRetrieveQuoteDetailsAction } from './formData/retrieveQuote';
import { retrieveQuoteDetailsReducer } from './formData/retrieveQuoteDetailsReducer';
import { UpdateConfirmationQuoteAction } from './quote/confirmationQuote';
import { confirmationQuoteReducer } from './quote/confirmationQuoteReducer';
import { quoteReducer, UpdateQuoteAction } from './quote/quote';
import { ReferenceDataAction } from './referenceData/actions';
import referenceDataReducer from './referenceData/reducer';
import referenceDataSaga from './referenceData/saga';
import { UpdateDisableDateChecksAction } from './timeTravel/actions';
import timeTravelReducer from './timeTravel/reducer';
import timeTravelSaga from './timeTravel/saga';

const rootReducer = combineReducers({
  error: errorReducer,
  petsDetails: petsDetailsReducer,
  referenceData: referenceDataReducer,
  customerDetails: customerDetailsReducer,
  jointPolicyholderDetails: jointPolicyholderDetailsReducer,
  policyDetails: policyDetailsReducer,
  quote: quoteReducer,
  quoteSummaryOptions: quoteOptionsReducer,
  retrieveQuoteDetails: retrieveQuoteDetailsReducer,
  timeTravel: timeTravelReducer,
  assumptionsAgreement: assumptionsAgreementReducer,
  confirmationQuote: confirmationQuoteReducer,
  additionalQuestionsShown: additionalQuestionsShownReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootAction =
  | ErrorAction
  | ReferenceDataAction
  | UpdatePetsDetailsAction
  | UpdateCustomerDetailsAction
  | UpdateJointPolicyholderDetailsAction
  | UpdatePolicyDetailsAction
  | UpdateQuoteInProgressAction
  | UpdateQuoteAction
  | UpdateQuoteOptionsAction
  | UpdateRetrieveQuoteDetailsAction
  | UpdateDisableDateChecksAction
  | UpdateAssumptionsAgreementAction
  | UpdateConfirmationQuoteAction
  | UpdateAdditionalQuestionsShownAction;

const createStore = (): Store<RootState, RootAction> => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const store = reduxCreateStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(referenceDataSaga);
  sagaMiddleware.run(quoteInProgressSaga);
  sagaMiddleware.run(quoteOptionsSaga);
  sagaMiddleware.run(assumptionsAgreementSaga);
  sagaMiddleware.run(timeTravelSaga);
  return store;
};

export default createStore;
